<template>
  <div class="pageContol">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">档案管理</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back()">一期一档</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">档案目录</a>
        </span>
      </div>
      <p style="padding: 8px 10px">
        <span style="margin-right: 15px"
          >企业名称：{{ payload.enterpriseName }}</span
        >
        <span style="margin-right: 15px"
          >班级名称：{{ payload.projectName }}</span
        >
        <span>期名称：{{ payload.phaesName }}</span>
      </p>
      <div class="framePage-body">
        <div class="framePage-scroll">
          <!-- <div class="ovy-a"> -->
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="目录"
                align="left"
                prop="dictValue"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  {{ scope.row.dictValue }}
                </template>
              </el-table-column>
              <el-table-column label="操作" align="center" width="80px">
                <div slot-scope="scope" class="flexcc">
                  <el-button
                    type="text"
                    style="padding: 0px 5px"
                    size="mini"
                    @click="handlEdit(scope.row)"
                    >查看</el-button
                  >
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          <!-- </div> -->
        </div>
      </div>
    </div>
    <el-dialog
      :visible.sync="dialogVisible"
      :title="title"
      top="5%"
      width="65%"
      :center="true"
      :before-close="doClose"
    >
      <div class="archives_box">
        <div id="pdf-content8" style="height: 600px"></div>
      </div>
    </el-dialog>
    <el-dialog
      :visible.sync="dialogVisible_list"
      :title="listTitle"
      top="5%"
      width="70%"
      :center="true"
      :before-close="doClose"
    >
      <div class="archives_box">
        <div class="data_box">
          <el-container style="height: 500px; border: 1px solid #eee">
            <el-aside
              width="290px"
              style="background-color: rgb(238, 241, 246)"
            >
              <template v-if="list.length">
                <div
                  v-for="(el, index) in list"
                  :key="index"
                  class="flexcb"
                  style="padding: 5px 0"
                  :class="activeInd == index ? 'active' : ''"
                >
                  <div style="display:flex;" @click="chooseItem(el, index)">
                    <div style="margin-right: 5px">{{ index + 1 + "." }}</div>
                    <div >
                      <span :title="el.docName" >{{
                        el.docName
                      }}</span>
                    </div>
                  </div>
                </div>
              </template>
              <div style="height: 100%" class="flexdcc" v-else>暂无数据</div>
            </el-aside>
            <el-main>
              <div
                v-if="this.url"
                class="df"
                id="pdf-content9"
                style="height: 100%"
              ></div>
              <p v-else>暂无数据</p>
            </el-main>
          </el-container>
        </div>
      </div>
    </el-dialog>
    <!-- <ArchivesOfpaper ref="ref_315" />培训考核试卷
    <PerformanceAssessment ref="ref_320" />学员考核成绩统计表
    <SafetyTrainingSignUp ref="ref_305" />安全培训教育记录及签字表
    <TrainDate ref="ref_300" />培训日程安排通知/也可称为培训计划
    <SafetyTrainingHS ref="ref_340" />安全培训教材会审表
    <SafetyTrainingHandout ref="ref_325" />安全培训教材或课程讲义
    <TrainOfVideo ref="ref_330" /> 培训影像资料
    <ContractOrCopy ref="ref_335" />与培训机构签订的服务合同或者协议复印件
    <AssessmentReport ref="ref_310" />本期综合考评报告 -->
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import pdf from "pdfobject";
import List from "@/mixins/List";
import { resetKeepAlive } from "@/utils/common";
import { mapGetters } from "vuex";
export default {
  name: "OneDateOfCatalogJg",
  components: {
    Empty,
  },
  mixins: [List],
  data() {
    return {
      dialogVisible: false,
      dialogVisible_list: false,
      tableData: [],
      // 接口参数,传到下一个页面
      payload: {},
      list: [],
      url: "",
      listTitle:'',
      title:''
    };
  },
  created() {
    this.payload = JSON.parse(this.$route.query.payload);
    this.queryList();
    // console.log(this.payload);
  },
  computed: {
    ...mapGetters({
      downloadItems: "getDownloadItems",
    }),
  },
  methods: {
    // 查询档案目录
    queryList() {
      this.$post("/dm/phase/record/category/list").then((res) => {
        // ;
        this.tableData = res.data || [];
      });
    },
    /**
     * 编辑
     */
    handlEdit(row) {
      // 判断类型
      let dictCode = row.dictCode;
      let payload = {
        ...row,
        ...this.payload,
      };
      let apiUrl = "";
      if (dictCode == "325" || dictCode == "330" || dictCode == "370") {
        // zip格式
        apiUrl =
          dictCode == "325"
            ? "/dm/phase/record/textbook/download"
            : "/dm/phase/record/video/download";
        this.$alert("请点击下载查看", {
          confirmButtonText: "下 载",
          confirmButtonClass: "bgc-bv",
          center: true,
          callback: (action) => {
            if (action == "confirm") {
              this.doDownLoad(apiUrl, payload);
            }
          },
        });
      } else if (dictCode == "335" || dictCode == "340") {
        // 带列表的pdf
        apiUrl =
          dictCode == "335"
            ? "/dm/phase/record/contract/list"
            : "/dm/phase/record/audit/list";
        this.listTitle =  dictCode == "335"
            ? "与培训机构签订的服务合同或者协议复印件"
            : "安全培训教材会审表";
        this.queryPdfList(apiUrl, payload);
      } else {
        // pdf
        if (dictCode == "315") {
          apiUrl = "/documentcenter/getTrainExamPaper";
          this.title = '培训考核试卷';
        } else if (dictCode == "320") {
          apiUrl = "/documentcenter/getLearnStatistic";
          this.title = '学员考核成绩统计表';
        } else if (dictCode == "305") {
          apiUrl = "/biz/dm/safetyTrainSign/download";
          this.title = '安全培训教育记录及签字表';
        } else if (dictCode == "300") {
          apiUrl = "/dmtrainplan/getTrainPlanReport";
          this.title = '培训计划';
        } else {
          apiUrl = "/biz/dm/trainComprehensiveAssessment/download";
          this.title = '本期综合考评报告';
        }
        this.doPreView(apiUrl, payload);
      }
    },
    // zip 下载
    doDownLoad(apiUrl, playload) {
      this.$post(apiUrl, {
        enterpriseId: playload.enterpriseId,
        phaseId: playload.phaseId,
        projectId: playload.projectId,
        compId: playload.compId,
      }).then((res) => {
        if (res.status == "0") {
          let list = res.data;
          console.log(list);
          if (!this.downloadItems.includes(list.taskId)) {
            this.$store.dispatch("pushDownloadItems", list.taskId);
          } else {
            this.$message.warning(
              "[" + list.fileName + "]已经申请下载,请耐心等待"
            );
          }
        } else {
          this.$message.error(res.message);
        }
      });
    },
    // 预览 pdf
    doPreView(apiUrl, playload) {
      this.$post(apiUrl, {
        phaseId: playload.phaseId,
        enterpriseId: playload.enterpriseId,
        compId: playload.compId,
        updated: 0,
      }).then((res) => {
        if (res.status == "0") {
          this.dialogVisible = true;
          let url = res.data.url || res.data.viewUrl;
          this.$nextTick(() => {
            pdf.embed(url, "#pdf-content8", {
              pdfOpenParams: { scrollbars: "0", toolbar: "0", statusbar: "1" },
            });
          });
        }
      });
    },
    // 带列表的pdf
    queryPdfList(apiUrl, playload) {
      this.$post(apiUrl, {
        enterpriseId: playload.enterpriseId,
        phaseId: playload.phaseId,
        projectId: playload.projectId,
        compId: playload.compId,
      }).then((res) => {
        if (res.status == "0") {
          this.dialogVisible_list = true;
          this.list = res.data || [];
          if (this.list.length) {
            this.activeInd = 0;
            this.url = this.list[0].fileKey;
            this.previewPdfItem();
          } else {
            this.url = "";
          }
        }
      });
    },
    // 带列表的pdf选择每项
    chooseItem(el, index) {
      this.url = el.fileKey;
      this.activeInd = index;
      this.previewPdfItem();
    },
    // 预览列表的每一项
    previewPdfItem() {
      this.$nextTick(() => {
        pdf.embed(this.url, "#pdf-content9", {
          pdfOpenParams: { scrollbars: "0", toolbar: "0", statusbar: "1" },
        });
      });
    },
    // 关闭弹窗
    doClose(done) {
      if (typeof done == "function") {
        done();
      } else {
        this.dialogVisible = false;
        this.dialogVisible_list = false;
      }
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 2.55) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1 + 40;
      }
      if (page) {
        tHeight -= 25;
      }
      this.tableHeight = tHeight;
    },
  },
  beforeRouteLeave: resetKeepAlive,
  watch: {
    $route: {
      handler: function (val, oldVal) {
        if (val.query.refresh) {
          // this.getData(-1);
        } else {
          // this.getData();
        }
      },
      // 深度观察监听
      deep: true,
    },
  },
};
</script>
<style lang="less">
.dialogBox {
  height: 600px;
  .title {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0.75rem 0;
    h3 {
      padding-bottom: 0.75rem;
    }
  }
  .divBox2 {
    width: 100%;
  }
}
tr {
  height: 32px;
}
</style>
